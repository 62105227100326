import React, { useRef, useState } from "react";
import Rvscops from '../Asset/Home/pharmacydesktop.png'
import RvscopsMobile from '../Asset/Home/pharmacy.png'
import banner1 from '../Asset/back.png';
import Mobilebanner from '../Asset/mobile.png';
import capture from "../Asset/capture.png"
import { BsFillCloudCheckFill } from 'react-icons/bs'
import { MdOutlineAppRegistration } from "react-icons/md";
import { HiOutlineLocationMarker, HiOutlineCalendar, HiOutlineDownload, HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { FaCloudUploadAlt } from "react-icons/fa";
import muthu from "../Asset/muthu.png"
import neelavaeni from "../Asset/neelavaeni.png"
import vasantha from "../Asset/vasantha kananan.png"
import Kalaiselvan from "../Asset/Kalaiselvan.png"
import Sampath from "../Asset/Sampath.png"
import Scientia from "../Asset/Scientia 2023.png"
import axios from "axios";

function Banner() {

    const [datavalues, setDataValues] = useState({
        accountname: "",
        bankname: "",
        refnumber: "",
        date: "",
        amount: "",
        anydetails: "",
        name: "",
        designation: "",
        designationtype: "",
        contactnumber: "",
        participanttype: "individual",
        email: "",
        insitution: "",
        accommodation: "no",
        gender: "",
        accommodationdays: "",
        scientificpresentation: "no",
        scientificradio: "",
        areapresentation: "",
        bankrecepit: "",
        oralabstract: "",
        oralppt: "",
        posterabstract: "",
        honorifics: ""

    })
    const ref = useRef(null);
    // select designation
    const handelSelectdesig = (e) => {
        const { name, value } = e.target.value;

        datavalues.designation = e.target.value;

        values.designation = '';

        values.designation = e.target.value;

        setDataValues({ ...datavalues, designation: e.target.value })

        let designationlist = Option.filter(d => d.degination1 === e.target.value);
        setDesig(designationlist[0]["type"])

        if (name === "designation" && value === 'designation') {
            values[name] = value;
            values.designationtype = '';
            setDataValues({
                ...datavalues,
                [name]: value,
                designationtype: "",
            })
        } else {
            values[name] = value;
            setDataValues({
                ...datavalues,
                [name]: value,
                designationtype: "",
            })
        }

        if (submit) {
            const error = validation(values)
            setError(error)
        }

    }

    const [desig, setDesig] = useState()

    const handleDesig = (e) => {

        const { name, value } = e.target
        ////console.log(e.target.value);
        // set Data Values
        datavalues.designationtype = e.target.value;

        values.designationtype = ''

        values.designationtype = e.target.value;

        setDataValues({ ...datavalues, designationtype: e.target.value });

        if (submit) {
            const error = validation(values)
            setError(error)
        }

    }

    const Option = [
        {
            id: 0,
            degination1: 'Student',
            type: [
                "UG",
                "PG",
                "Ph.D"
            ]
        },
        {
            id: 1,
            degination1: 'Faculty',
            type: [
                "Professor",
                "Associate Professor",
                "Assistant Professor",
                "Lecturer",
                "Other"
            ]
        },
        {
            id: 2,
            degination1: 'Industrialist',
        },
        {
            id: 3,
            degination1: 'Research Scholar',
        },
        {
            id: 4,
            degination1: 'Other',
        },
    ]

    const [values, setValues] = useState({
        accountname: "",
        bankname: "",
        refnumber: "",
        date: "",
        amount: "",
        anydetails: "",
        name: "",
        designation: "",
        designationtype: "",
        contactnumber: "",
        participanttype: "individual",
        email: "",
        insitution: "",
        accommodation: "no",
        gender: "",
        accommodationdays: "",
        scientificpresentation: "no",
        scientificradio: "",
        areapresentation: "",
        bankrecepit: "",
        oralabstract: "",
        oralppt: "",
        posterabstract: "",
        honorifics: ""
    })
    const [error, setError] = useState({})
    const [submit, setSubmit] = useState(false)
    const validation = () => {
        let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        let phnoregex = /^[6-9]\d{9}$/;
        let errors = {};
        if (values.accountname === "") {
            errors.accountname = "Enter Your Name*"
        }
        if (values.bankname === "") {
            errors.bankname = "Enter Your Bank Name*"
        }
        if (values.refnumber === "") {
            errors.refnumber = "Enter Your Ref Number*"
        }
        if (values.date === "") {
            errors.date = "Pick The Date*"
        }
        if (values.amount === "") {
            errors.amount = "Enter The Amount Paid*"
        }
        // if (values.anydetails === "") {
        //     errors.anydetails = "Enter The Details*"
        // }
        if (values.bankrecepit === "") {
            errors.bankrecepit = "Upload Bank Recepit*"
        }
        if (values.name === "") {
            errors.name = "Enter Your Name*"
        }
        if (values.designation === "") {
            errors.designation = "Select Your Designation*"
        } else if (values.designationtype === "") {
            errors.designationtype = "Select Your Designation Type*"
        }
        if (values.contactnumber === "") {
            errors.contactnumber = "Enter Your Contact Number*"
        } else if (!phnoregex.test(values.contactnumber)) {
            errors.contactnumber = "Enter a Valid Contact Number*"
        } if (values.participanttype === "") {
            errors.participanttype = "Select Your Participant Type*"
        }
        if (values.email === "") {
            errors.email = "Enter Your Email*"
        } else if (!emailregex.test(values.email)) {
            errors.email = "Enter a Valid Email*"

        }
        if (values.insitution === "") {
            errors.insitution = "Enter The Insitution Name*"
        }

        ////console.log(values.accommodation)
        if (values.accommodation === "") {
            errors.accommodation = "Choose Yes (If  Accommodation is Needed)*"
        } else if (values.accommodation === "yes") {
            if (values.accommodationdays === "") {
                errors.accommodationdays = "Enter The No of Days Accommodation Needed*"
            }
            if (values.gender === "") {
                errors.gender = "Select your Gender*"
            }
        }
        if (values.scientificpresentation === "") {
            errors.scientificpresentation = "Choose Yes (If you have presentation)*"
        } else if (values.scientificpresentation === "yes") {
            if (values.scientificradio === "") {
                errors.scientificradio = "Choose the Type of Presentation*"
            } else if (values.scientificradio === "oral") {
                if (values.areapresentation === "") {
                    errors.areapresentation = "Choose the Area of Presentation*"
                }
                if (values.oralppt === "") {
                    errors.oralppt = "Upload Oral PPT*"
                }
                if (values.oralabstract === "") {
                    errors.oralabstract = "Upload Oral Abstract*"
                }
            } else if (values.scientificradio === "poster") {
                if (values.areapresentation === "") {
                    errors.areapresentation = "Choose the Area of Presentation*"
                }
                if (values.posterabstract === "") {
                    errors.posterabstract = "Upload Oral PPT*"
                }
            }
        }
        return errors
    }

    const handleChange = (e) => {

        const { name, value, checked, type } = e.target
        e.preventDefault()

        if (name === "scientificpresentation" && value === "no") {
            values[name] = value
            values.areapresentation = ""
            datavalues.oralabstract = ""
            datavalues.oralppt = ""
            datavalues.posterabstract = ""
            values.scientificradio = ""
            setDataValues({
                ...datavalues,
                [name]: value,
                areapresentation: "",
                scientificradio: ""
            })
        }
        else if (name === "accommodation" && value === "no") {
            values[name] = value;
            values.accommodationdays = ""
            values.gender = ""
            setDataValues({
                ...datavalues,
                [name]: value,
                accommodationdays: "",
                gender: "",
            })

        } else if (type === "file") {
            values[name] = value;
            setDataValues({
                ...datavalues,
                [name]: e.target.files[0],
            })
        } else {

            values[name] = value
            setDataValues({
                ...datavalues,
                [name]: value,
            })
        }
        if (submit) {
            const error = validation(values)
            setError(error)
        }

    }
    function handleSubmit() {
        setSubmit(true)
        const error = validation(values)
        setError(error)
        console.log(error);
        if (Object.keys(error).length === 0) {
            let paymentDetails = {
                accountname: datavalues.accountname,
                bankname: datavalues.bankname,
                refnumber: datavalues.refnumber,
                date: datavalues.date,
                amount: datavalues.amount,
                anydetails: datavalues.anydetails,
            }
            // console.log(paymentDetails);
            var formdata = new FormData();
            formdata.append("paymentDetails", JSON.stringify(paymentDetails))
            formdata.append("name", (datavalues.honorifics + datavalues.name).toString().toUpperCase())
            formdata.append("designation", datavalues.designation)
            formdata.append("designationtype", datavalues.designationtype)
            formdata.append("contactnumber", datavalues.contactnumber)
            formdata.append("email", datavalues.email)
            formdata.append("institution", datavalues.insitution)
            formdata.append("bankrecepit", datavalues.bankrecepit)
            formdata.append("accommodationRequired", JSON.stringify({ require: datavalues.accommodation, gender: datavalues.gender, noofdaysRequire: datavalues.accommodationdays === "" ? undefined : datavalues.accommodationdays }))
            formdata.append("scientificpresentation", JSON.stringify({
                presentation: datavalues.scientificpresentation, typeofPresentation: datavalues.scientificradio
                , areaofPresentation: datavalues.areapresentation
            }))
            formdata.append("oralabstract", datavalues.oralabstract === "" ? undefined : datavalues.oralabstract)
            formdata.append("oralppt", datavalues.oralppt === "" ? undefined : datavalues.oralppt)
            formdata.append("posterabstract", datavalues.posterabstract === "" ? undefined : datavalues.posterabstract)

            // console.log(formdata);

            axios.post(`https://rvscas.com/api/pharmacyseminar`, formdata)
            // axios.post(`http://192.168.101.156:7000/api/pharmacyseminar`, formdata)
                .then(res => {
                    setDataValues({
                        accountname: "",
                        bankname: "",
                        refnumber: "",
                        date: "",
                        amount: "",
                        anydetails: "",
                        name: "",
                        designation: "",
                        designationtype: "",
                        contactnumber: "",
                        participanttype: "individual",
                        email: "",
                        insitution: "",
                        accommodation: "no",
                        gender: "",
                        accommodationdays: "",
                        scientificpresentation: "no",
                        scientificradio: "",
                        areapresentation: "",
                        bankrecepit: "",
                        oralabstract: "",
                        oralppt: "",
                        posterabstract: "",
                        honorifics: ""

                    })
                    setValues({
                        accountname: "",
                        bankname: "",
                        refnumber: "",
                        date: "",
                        amount: "",
                        anydetails: "",
                        name: "",
                        designation: "",
                        designationtype: "",
                        contactnumber: "",
                        participanttype: "individual",
                        email: "",
                        insitution: "",
                        accommodation: "no",
                        gender: "",
                        accommodationdays: "",
                        scientificpresentation: "no",
                        scientificradio: "",
                        areapresentation: "",
                        bankrecepit: "",
                        oralabstract: "",
                        oralppt: "",
                        posterabstract: "",
                        honorifics: ""

                    })
                    setSubmit(false)
                    if (res.status === 200) {
                        localStorage.setItem("pharmacy", "sssccciiieeennntttiiiaaa");
                        window.location = "/thankyou"
                    }
                }).catch(err => {
                    console.log(err)
                    alert("please try again")
                })


        }

    }

    // const downloadFile = () => {
    //     window.location.href = "../Asset/Account-details.pdf"
    // }

    // console.log(datavalues);

    function downloadAll(urls) {

        var link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        for (var i = 0; i < urls.length; i++) {
            link.setAttribute('download', urls[i]["name"]);
            link.setAttribute('href', urls[i]["link"]);
            link.click();
        }
        document.body.removeChild(link);
    }
    function downloadRegister(urls) {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        var link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        for (var i = 0; i < urls.length; i++) {
            link.setAttribute('download', urls[i]["name"]);
            link.setAttribute('href', urls[i]["link"]);
            link.click();
        }
        document.body.removeChild(link);
    }

    const [dayOneState, setdayOneState] = useState(true)
    const [dayTwoState, setdayTwoState] = useState(false)
    const dayone = () => {
        setdayOneState(true)
        setdayTwoState(false)
    }
    const daytwo = () => {
        setdayOneState(false)
        setdayTwoState(true)
    }
    // console.log(datavalues);
    // console.log(datavalues.scientificpresentation);
    // console.log(datavalues.scientificradio)
    return (
        <>

            <div className="relative">
                <div>
                    <div className="relative">
                        <div className="">
                            <div className="absolute top-8 w-[100%]">
                                <div className="container mx-auto">
                                    <img src={Rvscops} className="sm:hidden md:hidden mdsm:hidden lg:block w-[80%] mx-auto" />
                                    <img src={RvscopsMobile} className="sm:block md:block mdsm:block lg:hidden w-[100%] mx-auto md:h-[100px] mdsm:h-[100px]  sm:h-[60px]" />
                                </div>
                            </div>
                            <img src={banner1} alt="drug discovery process" className="h-[100vh] sm:hidden md:hidden mdsm:hidden lg:block  overflow-hidden  object-cover w-[100%]" />
                            <img src={Mobilebanner} alt="drug discovery process" className="h-[100vh] sm:block md:block mdsm:block lg:hidden object-cover w-[100%]" />

                            <div className="absolute bottom-0 w-[100%]">
                                <div className="container mx-auto">
                                    <div className="w-[100%] flex pt-[30px] sm:block pb-[80px] sm:px-4 md:px-4 mdsm:px-4">
                                        <div className="lg:w-[60%] sm:w-[100%] md:w-[100%] mdsm:w-[100%]">
                                            <div className="pb-6">

                                                <img src={Scientia} className="w-[50%] sm:px-4 " />
                                            </div>

                                            <p className="text-white text-left text-[20px] sm:text-sm title sm:px-4  pb-1">Two days - International Conference On</p>
                                            <h1 className="text-[#fff] text-[30px] sm:text-[16px] sm:leading-[26px] sm:px-4 pb-8 base-font text-left leading-[40px]">
                                                Strategy, Concepts and Challenges in Drug Discovery and Development
                                            </h1>



                                            <div className="pb-16 ">


                                                <div className="flex group">
                                                    <HiOutlineDownload className="text-[#f2ae1b] group-hover:text-white text-h2  mx-4 sm:mx-3" />
                                                    <a href={require("../Asset/Guidelines.pdf")} download>
                                                        <button className="text-[#fff] text-[16px] pb-2 sm:text-[12px] underline underline-offset-4 text-left ">
                                                            Guidelines for abstract submission, oral and poster
                                                            presentation - SCIENTIA 23
                                                        </button>
                                                    </a>

                                                </div>


                                                <div className="flex  group">
                                                    <HiOutlineDownload className="text-[#f2ae1b] group-hover:text-white text-h2  mx-4 sm:mx-2" />


                                                    <button onClick={() => downloadAll([{
                                                        name: "Template -Abstract",
                                                        link: require("../Asset/Template -Abstract.pdf")
                                                    },
                                                    {
                                                        name: "Template -Poster presentation",
                                                        link: require("../Asset/Template -Poster presentation.pdf")
                                                    },
                                                    {
                                                        name: "Template  -Oral presentation",
                                                        link: require("../Asset/Template  -Oral presentation.pptx")
                                                    },

                                                    ])} className="text-[#fff] text-[16px] sm:text-[12px] underline underline-offset-4 text-left">
                                                        Templates for oral and poster presentation - SCIENTIA 23
                                                    </button>


                                                </div>

                                            </div>
                                            {/* <div className="sm:block md:hidden mdsm:hidden lg:hidden px-4">
                                                <div className="grid grid-cols-2 sm:grid-cols-1">
                                                    <div className="flex w-[100%] ">
                                                        <div className="h-[40px] w-[40px] center rounded-md py-2 px-1 bg-white">
                                                            <MdOutlineAppRegistration className="text-primary mx-auto text-[24px]" />
                                                        </div>
                                                        <div className="text-left text-[#fff] px-4 pt-1">
                                                            <p className=" text-sm">
                                                                Last Date of Registration
                                                            </p>
                                                            <p className="title text-sm">
                                                                July 15
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex w-[100%] pt-4">
                                                        <div className="h-[40px] w-[40px] center rounded-md py-2 px-1 bg-white">
                                                            <HiOutlineCalendar className="text-primary mx-auto text-[24px]" />
                                                        </div>
                                                        <div className="relative ">
                                                            <div className="text-left text-[#fff] px-4 pt-1">
                                                                <p className=" text-sm">
                                                                    Conference
                                                                </p>
                                                                <p className="title text-sm w-[100%]">
                                                                    27 & 28 July 2023
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex w-[100%] pt-4">
                                                        <div className="h-[40px] w-[40px] center rounded-md py-2 px-1 bg-white">
                                                            <HiOutlineLocationMarker className="text-primary mx-auto text-[24px]" />
                                                        </div>
                                                        <div className="relative ">
                                                            <div className="text-left text-[#fff] px-4 pt-1">
                                                                <p className=" text-sm">
                                                                    Venue
                                                                </p>
                                                                <p className="title text-sm w-[100%]">
                                                                    Kalam Hall
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}



                                        </div>
                                    </div>

                                    <div className="w-[40%] ">
                                        <div className="w-[80%] relative mx-auto rounded-md ">
                                            <div className="" >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="py-16">
                    <div className="container mx-auto">
                        <h1 className="text-[36px] sm:text-center title pt-6 pb-4 sm:pt-4  sm:px-4 sm:text-h3 text-secondary text-centre sm:pb-4">Speakers </h1>

                        <div className="flex sm:block sm:px-4">
                            <button onClick={() => dayone()} className="sm:w-[100%]">
                                <div className={`${dayOneState ? 'bg-primary text-white ' : 'bg-icon-color'}  sm:w-[100%] sm:text-[16px] title text-[20px]  py-2 px-10  `}>
                                    27 Jul
                                </div>
                            </button>
                            <div className="flex sm:w-[100%]">
                                <button className={`${dayTwoState ? 'bg-primary' : 'bg-icon-color'} text-white py-2 px-10 title text-[20px] sm:text-[16px]  sm:w-[100%]`} onClick={() => daytwo()}>28 Jul</button>
                            </div>
                        </div>


                        {dayOneState ?
                            <div className="w-[100%] py-6 ">
                                <div className="w-[100%] sm:p-4 grid lg:grid-cols-2 mdsm:grid-col-1 md:grid-col-1 sm:grid-col-1 gap-2">

                                    <div className=" sm:w-[100%] sm:mb-4  sm:mx-0 flex sm:block border p-4 rounded-md">
                                        <div className="w-[25%] sm:w-[100%] ">
                                            <img src={vasantha} alt="vasantha" className="h-[200px] w-[200px] sm:w-[100%] sm:h-[100%] object-cover rounded-md" />
                                        </div>
                                        <div className="w-[75%] sm:w-[100%]  px-4 sm:px-0 relative">
                                            <h1 className="text-primary  text-left pt-4 sm:text-h6 pb-2 font-semibold text-h4 ">Dr . Vasanthanathan Poongavanam</h1>
                                            <ul>
                                                <li className="text-left text-text-color sm:text-sm">Lead Computational Chemist,Senior Scientist,</li>
                                                <li className="text-left text-text-color sm:text-sm">Department of Chemistry-BMC,</li>
                                                <li className="text-left text-text-color sm:text-sm">Uppsala University, Sweden.</li>
                                            </ul>
                                            <div className="card-down ">
                                                <p className="text-text-color  text-left title pt-6 font-semibold sm:text-sm">Drug Discovery beyond Traditional Molecular space</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className=" sm:w-[100%] sm:mb-4  sm:mx-0 flex sm:block border p-4 rounded-md">
                                        <div className="w-[25%] sm:w-[100%]">
                                            <img src={neelavaeni} alt="neelavaeni" className="h-[200px] w-[200px] sm:w-[100%] sm:h-[100%] object-cover rounded-md" />
                                        </div>
                                        <div className="w-[75%] sm:w-[100%]  px-4 sm:px-0 relative">
                                            <h1 className="text-primary  text-left pt-4 sm:text-h6 pb-2 font-semibold text-h4 ">Dr. Neelaveni Thangavel,</h1>
                                            <ul>
                                                <li className="text-left text-text-color sm:text-sm">Professor,Department of Pharmaceutical Chemistry,</li>
                                                <li className="text-left text-text-color sm:text-sm">College of Pharmacy, Jazan University,</li>
                                                <li className="text-left text-text-color sm:text-sm">Saudi Arabia.</li>
                                            </ul>
                                            <div className="card-down">
                                                <p className="text-text-color  text-left title pt-6 font-semibold sm:text-sm">Cancer Nanotheranostics: Concepts, Characters, and Design</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className=" sm:w-[100%] flex sm:block sm:mx-0  border p-4 rounded-md">
                                        <div className="w-[25%] sm:w-[100%]">
                                            <img src={muthu} alt="muthu" className="h-[200px] w-[200px] sm:w-[100%] sm:h-[100%] object-cover rounded-md" />
                                        </div>
                                        <div className="w-[75%] sm:w-[100%]  px-4 sm:px-0 relative">
                                            <h1 className="text-primary  text-left pt-4 sm:text-h6 pb-2 font-semibold text-h4 ">Dr.M.S. Muthu</h1>
                                            <ul>
                                                <li className="text-left text-text-color sm:text-sm">Associate Professor,</li>
                                                <li className="text-left text-text-color sm:text-sm">Department of Pharmaceutical Engineering and Technology,Indian Institute of Technology (BHU),</li>
                                                <li className="text-left text-text-color sm:text-sm">Varanasi.</li>
                                            </ul>
                                            <div className="card-down">
                                                <p className="text-text-color  text-left title pt-6 font-semibold sm:text-sm">Nanomedicine : <span className="text-text-color  text-left title pt-6 sm:text-sm" >An Emerging platform for Targeted Drug Delivery, Cancer Therapy and Imaging</span>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>



                            </div> : null}
                        {dayTwoState ? <div className="w-[100%] py-6 ">
                            <div className="w-[100%] sm:p-4 grid lg:grid-cols-2 mdsm:grid-col-1 md:grid-col-1 sm:grid-col-1 gap-2">

                                <div className=" sm:w-[100%] sm:mb-4  sm:mx-0 flex sm:block border p-4 rounded-md">
                                    <div className="w-[25%] sm:w-[100%] ">
                                        <img src={Kalaiselvan} alt="Kalaiselvan" className="h-[200px] w-[200px] sm:w-[100%] sm:h-[100%] object-cover rounded-md" />
                                    </div>
                                    <div className="w-[75%] sm:w-[100%]  px-4 sm:px-0 relative">
                                        <h1 className="text-primary  text-left pt-4 sm:text-h6 pb-2 font-semibold text-h4 ">Dr. V. Kalaiselvan</h1>
                                        <ul>
                                            <li className="text-left text-text-color sm:text-sm">Senior Principal Scientific Officer,</li>
                                            <li className="text-left text-text-color sm:text-sm">Indian Pharmacopoeia Commission (IPC),</li>
                                            <li className="text-left text-text-color sm:text-sm">Ghaziabad.</li>
                                        </ul>
                                        <div className="card-down ">
                                            <p className="text-text-color  text-left title pt-6 font-semibold sm:text-sm">Pharmacist's Engagement in Medical Products including Medical Devices Safety Monitoring - Scope and Contemporary Challenges</p>
                                        </div>

                                    </div>
                                </div>

                                <div className=" sm:w-[100%] sm:mb-4  sm:mx-0 flex sm:block border p-4 rounded-md">
                                    <div className="w-[25%] sm:w-[100%]">
                                        <img src={Sampath} alt="Sampath" className="h-[200px] w-[200px] sm:w-[100%] sm:h-[100%] object-cover rounded-md" />
                                    </div>
                                    <div className="w-[75%] sm:w-[100%]  px-4 sm:px-0 relative">
                                        <h1 className="text-primary  text-left pt-4 sm:text-h6 pb-2 font-semibold text-h4 ">Dr. Sampathkumar Devarajan</h1>
                                        <ul>
                                            <li className="text-left text-text-color sm:text-sm">Executive Director & Chief Scientific Officer</li>
                                            <li className="text-left text-text-color sm:text-sm">Axxelent Pharma Science Pvt Ltd.</li>
                                            <li className="text-left text-text-color sm:text-sm">Chennai.</li>
                                        </ul>
                                        <div className="card-down">
                                            <p className="text-text-color  text-left title pt-6 font-semibold sm:text-sm">Formulation Development of Solid Dosage Forms Using Artificial Intelligence (AI) Technologies</p>
                                        </div>

                                    </div>
                                </div>
                            </div>



                        </div> : null}








                    </div>
                </div>






                {/* <div className="bg-[#fff]" ref={ref}>
                    <div className="container mx-auto pt-0 py-16">
                        <div className="w-[100%] flex py-16 sm:block sm:pt-0">
                            <div className="lg:w-[70%] mdsm:w-full md:w-full  sm:w-[100%]">



                                <h1 id="register" class="text-h1 title pt-0 sm:pt-4 sm:px-4 sm:text-h3 text-secondary text-left md:px-4 mdsm:px-4 lg:px-0">Personal Details</h1>
                                <div className="w-[100%] flex flex-wrap sm:block sm:px-4 md:px-4 mdsm:px-4 lg:px-0">


                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <div className="flex flex-col justify-start items-start">
                                            <div className="flex w-[100%] sm:w-[100%]">
                                                <div className="w-[20%]">
                                                    <select
                                                        id="honorifics"
                                                        name="honorifics"
                                                        onChange={(e) => handleChange(e)}
                                                        value={datavalues.honorifics}
                                                        autoComplete="honorifics"
                                                        className="border-b-[1px] sm:text-sm focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[100%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color  border-border-color py-3"
                                                    >
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Ms.">Ms.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                        <option value="Dr.">Dr.</option>
                                                    </select>
                                                </div>
                                                <div className="w-[80%] ">
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        name="name"
                                                        id="name"
                                                        onChange={(e) => handleChange(e)}
                                                        value={datavalues.name}
                                                        className="border-b-[1px] sm:text-sm flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3"
                                                    />
                                                </div>


                                            </div>
                                            {error.name ?
                                                <div className=""> <span className="text-add-color font-semibold text-[12px]">{error.name}</span></div> : null}
                                        </div>
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <div className="">
                                            <select
                                                value={datavalues.designation}
                                                name="designation"
                                                id="designation"
                                                onChange={(e) => handelSelectdesig(e)}
                                                className="border-b-[1px] sm:text-sm focus:placeholder:text-text-black flex  placeholder:text-[#888888] bg-[#fff] w-[80%] sm:w-[100%]  my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color  border-border-color py-3"
                                            >
                                                <option style={{ display: "none" }}>Select Designation</option>
                                                {Option.map((v, i) => <><option value={v.degination1}>{v.degination1}</option></>)}
                                            </select>
                                            {error.designation ?
                                                <div className="flex pt-1"> <span className="text-add-color tracking-wide	 text-left font-semibold text-[12px]">{error.designation}</span></div> : null}
                                        </div>
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <div className="">

                                            <div>
                                                {
                                                    datavalues.designation === 'Industrialist' || datavalues.designation === 'Research Scholar' || datavalues.designation === 'Other'
                                                        ?
                                                        <><div>
                                                            <input
                                                                type="text"
                                                                id="designationtype"
                                                                name="designationtype"
                                                                onChange={(e) => handleDesig(e)}
                                                                placeholder={`${datavalues.designation === 'Industrialist'? "industrialist Type":""} ${datavalues.designation === 'Research Scholar'? "Research Scholar Type":""}${datavalues.designation === 'Other'? "Other":""}`}

                                                                value={datavalues.designationtype}
                                                                className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm" />
                                                        </div></> :

                                                        <div>
                                                            <select
                                                                id="designationtype"
                                                                name="designationtype"
                                                                value={datavalues.designationtype}
                                                                onChange={(e) => handleDesig(e)}
                                                                className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                                            >
                                                                <option style={{ display: "none" }}>{datavalues.designation === "Student"? <>Select Course</>:datavalues.designation === "Faculty"? <>Select Faculty Type</>:<>Select Designation Type</>}</option>
                                                                {datavalues.designation === "Student" || datavalues.designation === "Faculty" ?
                                                                    <>
                                                                        {desig.map((d, i) => <><option value={d.type}>{d}</option></>)}
                                                                    </> : null}
                                                            </select>

                                                        </div>

                                                }

                                            </div>
                                            {error.designationtype ?
                                                <div className="flex pt-1"> <span className="text-add-color tracking-wide	 text-left font-semibold text-[12px]">{error.designationtype}</span></div> : null}
                                        </div>
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <div className="">

                                            <input
                                                type="text"
                                                placeholder="Contact Number"
                                                name="contactnumber"
                                                id="contactnumber"
                                                maxLength={10}
                                                onChange={(e) => handleChange(e)}
                                                onKeyPress={(event) => { if (!/^[0-9.]+$/.test(event.key)) { event.preventDefault() } }}

                                                value={datavalues.contactnumber}
                                                className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                            />
                                            {error.contactnumber ?
                                                <div className="flex pt-1"> <span className="text-add-color tracking-wide	 text-left font-semibold text-[12px]">{error.contactnumber}</span></div> : null}
                                        </div>
                                    </div>


                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <div className="">

                                            <input
                                                type="text"
                                                placeholder="E - Mail"
                                                name="email"
                                                id="email"
                                                onChange={(e) => handleChange(e)}
                                                value={datavalues.email}
                                                className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                            />    {error.email ?
                                                <div className="flex pt-1"> <span className="text-add-color tracking-wide	 text-left font-semibold text-[12px]">{error.email}</span></div> : null}
                                        </div>
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <div className="flex flex-col justify-start items-start">
                                            <input
                                                type="text"
                                                placeholder="Name of the Institution"
                                                name="insitution"
                                                id="insitution"
                                                onChange={(e) => handleChange(e)}
                                                value={datavalues.insitution}
                                                className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                            />{error.insitution ?
                                                <div className="flex pt-1"> <span className="text-add-color tracking-wide	 text-left font-semibold text-[12px]">{error.insitution}</span></div> : null}
                                        </div>
                                    </div>


                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-3 pb-2">
                                        <div className="">
                                            <div className="">
                                                <h5 className="text-left pb-3 font-semibold text-sm  text-primary">Accommodation Required</h5>
                                                <div className="flex ">
                                                    <label for="accomodation1" className={`${datavalues.accommodation === 'no' ? "bg-primary" : "bg-icon-color"} inline-flex text-white items-center border py-2 px-4 cursor-pointer`}>
                                                        <input
                                                            id="accomodation1"
                                                            name="accommodation"
                                                            type="checkbox"
                                                            className="hidden peer"
                                                            value="no"
                                                            onClick={(e) => handleChange(e)} /> <span className="font-sans text-base text-white">No</span>
                                                    </label>
                                                    <label for="accomodation" className={`${datavalues.accommodation === 'yes' ? "bg-primary" : "bg-icon-color"} inline-flex text-white items-center border py-2 px-4 cursor-pointer`}>
                                                        <input
                                                            id="accomodation"
                                                            name="accommodation"
                                                            type="checkbox"
                                                            className="hidden peer"
                                                            value="yes"
                                                            onClick={(e) => handleChange(e)} /> <span className="font-sans text-base text-white">Yes
                                                        </span>
                                                    </label>


                                                </div>
                                                {error.accommodation ?
                                                    <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.accommodation}</span></div> : null}
                                            </div>

                                        </div>

                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-6 sm:pt-2">
                                        {datavalues.accommodation === 'yes' ?
                                            <div className="">
                                                <input
                                                    type="number"
                                                    placeholder="Number of Days *"
                                                    name="accommodationdays"
                                                    id="accommodationdays"
                                                    onChange={(e) => handleChange(e)}
                                                    value={datavalues.accommodationdays}
                                                    className="border-b-[1px] flex focus:placeholder:text-text-black   placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                                />{error.accommodationdays ?
                                                    <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.accommodationdays}</span></div> : null}
                                            </div>
                                            : null}
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-6 sm:pt-2">
                                        {datavalues.accommodation === 'yes' ?
                                            <div className="">
                                                <h5 className="text-left pb-3 font-semibold text-sm  text-primary">Select Gender</h5>
                                                <div className="flex ">
                                                    <label for="gender1" className={`${datavalues.gender === 'male' ? "bg-primary" : "bg-icon-color"} inline-flex text-white items-center border py-2 px-4 cursor-pointer`}>
                                                        <input
                                                            id="gender1"
                                                            name="gender"
                                                            type="checkbox"
                                                            className="hidden peer"
                                                            value="male"
                                                            onClick={(e) => handleChange(e)} /> <span className="font-sans text-base text-white">Male</span>
                                                    </label>
                                                    <label for="gender" className={`${datavalues.gender === 'female' ? "bg-primary" : "bg-icon-color"} inline-flex text-white items-center border py-2 px-4 cursor-pointer`}>
                                                        <input
                                                            id="gender"
                                                            name="gender"
                                                            type="checkbox"
                                                            className="hidden peer"
                                                            value="female"
                                                            onClick={(e) => handleChange(e)} /> <span className="font-sans text-base text-white">female
                                                        </span>
                                                    </label>
                                                </div>
                                                {error.gender ?
                                                    <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.gender}</span></div> : null}
                                            </div>
                                            : null}
                                    </div>



                                </div>
                                <div className="w-[100%] flex flex-wrap  sm:px-4 md:px-4 mdsm:px-4 lg:px-0">
                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-4">
                                        <h5 className="text-left pb-3 font-semibold text-sm text-primary">Scientific Paper Presentation</h5>

                                        <div className="flex">

                                            <label className={`${datavalues.scientificpresentation === "no" ? "bg-primary" : "bg-icon-color"}  inline-flex text-white sm:text-sm items-center border py-2 px-4 cursor-pointer`}>
                                                <input name="scientificpresentation" type="checkbox" value="no" className="hidden peer" onClick={(e) => handleChange(e)} />
                                                <span className="font-sans text-base text-white">No</span>
                                            </label>
                                            <label
                                                className={`${datavalues.scientificpresentation === "yes" ? "bg-primary" : "bg-icon-color"}
                                            inline-flex text-white items-center border py-2 px-4 cursor-pointer`}>
                                                <input name="scientificpresentation" type="checkbox" value="yes" className="hidden peer" onClick={(e) => handleChange(e)} />
                                                <span className="font-sans text-base text-white">Yes</span>
                                            </label>
                                        </div>{error.scientificpresentation ?
                                            <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.scientificpresentation}</span></div> : null}

                                    </div>


                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-4">
                                        {datavalues.scientificpresentation === "yes" ?
                                            <div className="">
                                                <p className="text-left text-sm pb-3 font-semibold text-primary">Select Oral or Poster</p>
                                                <div className="flex">

                                                    <label for="Toggle1"
                                                        className={`inline-flex text-white items-center border hover:bg-primary 
                                                 ${datavalues.scientificradio === "oral" && datavalues.scientificpresentation === "yes" ? "bg-primary" : "bg-icon-color"} 
                                                 inline-flex text-white items-center border py-2 px-4 cursor-pointer`}>
                                                        <input id="Toggle1" value="oral" name="scientificradio"
                                                            onClick={(e) => handleChange(e)} type="checkbox" className="hidden peer" />
                                                        <span className="font-sans text-base text-white">Oral</span>
                                                    </label>
                                                    <label for="Toggle2" className={`inline-flex text-white items-center border hover:bg-primary ${datavalues.scientificradio === "poster" && datavalues.scientificpresentation === "yes" ? "bg-primary" : "bg-icon-color"} inline-flex text-white items-center border py-2 px-4 cursor-pointer`}>
                                                        <input id="Toggle2" value="poster" name="scientificradio" onClick={(e) => handleChange(e)} type="checkbox" className="hidden peer" />
                                                        <span className="font-sans text-base text-white">Poster</span>
                                                    </label>

                                                </div>
                                                {error.scientificradio ?
                                                    <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.scientificradio}</span></div> : null}
                                            </div> : null}
                                    </div>


                                    {datavalues.scientificradio === "oral" && datavalues.scientificpresentation === "yes" ? <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-8 sm:pt-4">

                                        <div className="">
                                            {datavalues.scientificradio === "oral" && datavalues.scientificpresentation === "yes" ?
                                                <div className="flex flex-col justify-start items-start">
                                                
                                                    <select
                                                        name="areapresentation" value={datavalues.areapresentation}
                                                        onChange={(e) => handleChange(e)}
                                                        className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                                
                                                    >
                                                        <option style={{ display: "none" }}>Select Area of Presentaion</option>
                                                        <option value='Pharmacognosy'>Pharmacognosy</option>
                                                        <option value='Pharmaceutical Chemistry'>Pharmaceutical Chemistry</option>
                                                        <option value='Pharmaceutical Analysis'>Pharmaceutical Analysis</option>
                                                        <option value='Pharmaceutics'>Pharmaceutics</option>
                                                        <option value='Pharmacology'>Pharmacology</option>
                                                        <option value='Pharmacy Practice'>Pharmacy Practice</option>
                                                    </select>
                                                    {error.areapresentation ?
                                                        <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.areapresentation}</span></div> : null}
                                                </div> : null}

                                        </div>
                                    </div> : null}


                                    {datavalues.scientificradio === "oral" && datavalues.scientificpresentation === "yes" ? <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] sm:pt-2 lg:pt-2 md:pt-7 mdsm:pt-7">

                                        <div className="">
                                            {datavalues.scientificradio === "oral" && datavalues.scientificpresentation === "yes" ?

                                                <div className="border  mt-[20px] bg-icon-color cursor-pointer h-[40px] w-[80%] sm:w-[100%] hover:bg-primary group  duration-300 hover:text-white text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color   border-icon-color  ">
                                                    <label for="file-oralabstract"
                                                        class=" w-[100%] border-primary  py-2 px-2 cursor-pointer flex items-center justify-center">
                                                        {datavalues.oralabstract !== "" ? <BsFillCloudCheckFill className="m-1 text-base text-primary" /> : <FaCloudUploadAlt className="m-1 text-base" />}Upload Abstract
                                                    </label>
                                                    <input
                                                        id="file-oralabstract"
                                                        type="file"
                                                        name="oralabstract"
                                                        onChange={(e) => handleChange(e)}
                                                        className="w-[100%]"
                                                    />
                                                    {datavalues.oralabstract != '' ? <div className="sm:text-center lg:text-left text-sm text-text-color ">{datavalues.oralabstract.name}</div> : <></>}
                                                    {error.oralabstract ?
                                                        <div className="flex pt-2"> <span className="text-add-color text-left font-semibold text-[12px]">{error.oralabstract}</span></div> : null}
                                                </div>
                                                : null}
                                        </div>

                                    </div> : null}



                                    {datavalues.scientificradio === "oral" && datavalues.scientificpresentation === "yes" ?
                                        <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-2">

                                            <div className="">
                                                {datavalues.scientificradio === "oral" && datavalues.scientificpresentation === "yes" ?

                                                    <div className="border  mt-[20px] bg-icon-color cursor-pointer h-[40px] w-[80%] sm:w-[100%] hover:bg-primary group  duration-300 hover:text-white text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color   border-icon-color  ">
                                                        <label for="file-oralppt"
                                                            class=" w-[100%] border-primary  py-2 px-2 cursor-pointer flex items-center justify-center">

                                                            {datavalues.oralppt !== "" ? <BsFillCloudCheckFill className="m-1 text-base text-primary" /> : <FaCloudUploadAlt className="m-1 text-base" />}Upload PPT
                                                        </label>
                                                        <input
                                                            id="file-oralppt"
                                                            type="file"
                                                            name="oralppt"
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                        {datavalues.oralppt != '' ? <div className="sm:text-center lg:text-left text-sm text-text-color ">{datavalues.oralppt.name}</div> : <></>}
                                                        {error.oralppt ?
                                                            <div className="flex pt-2"> <span className="text-add-color text-left font-semibold text-[12px]">{error.oralppt}</span></div> : null}

                                                    </div>
                                                    : null}
                                            </div>
                                        </div> : null}


                                    {datavalues.scientificradio === "poster" && datavalues.scientificpresentation === "yes" ?
                                        <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-8 sm:pt-4">

                                            <div className="">
                                                {datavalues.scientificradio === "poster" && datavalues.scientificpresentation === "yes" ?
                                                    <div className="border  mt-[20px] bg-icon-color cursor-pointer h-[40px] w-[80%] sm:w-[100%] hover:bg-primary group  duration-300 hover:text-white text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color   border-icon-color  ">

                                                        <label for="file-posterabstract"
                                                            class=" w-[100%] border-primary  py-2 px-2 cursor-pointer flex items-center justify-center">
                                                            {datavalues.posterabstract !== "" ? <BsFillCloudCheckFill className="m-1 text-base text-primary" /> : <FaCloudUploadAlt className="m-1 text-base" />}Upload Abstract
                                                        </label>
                                                        <input
                                                            id="file-posterabstract"
                                                            type="file"
                                                            name="posterabstract"
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                        {datavalues.posterabstract != '' ? <div className="sm:text-center lg:text-left text-sm text-text-color ">{datavalues.posterabstract.name}</div> : <></>}
                                                        {error.posterabstract ?
                                                            <div className="px-2"> <span className="text-add-color text-left font-semibold text-[12px]">{error.posterabstract}</span></div> : null}

                                                    </div> : null}
                                            </div>
                                        </div> : null}


                                    {datavalues.scientificradio === "poster" && datavalues.scientificpresentation === "yes" ?
                                        <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] lg:pt-4 sm:pt-4 md:pt-9 mdsm:pt-9">

                                            <div >
                                                {datavalues.scientificradio === "poster" && datavalues.scientificpresentation === "yes" ? <div>
                                                    <select className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm" name="areapresentation" value={datavalues.areapresentation} onChange={(e) => handleChange(e)}>
                                                        <option style={{ display: "none" }}>Select Area of Presentaion</option>
                                                        <option value='Pharmacognosy'>Pharmacognosy</option>
                                                        <option value='Pharmaceutical Chemistry'>Pharmaceutical Chemistry</option>
                                                        <option value='Pharmaceutical Analysis'>Pharmaceutical Analysis</option>
                                                        <option value='Pharmaceutics'>Pharmaceutics</option>
                                                        <option value='Pharmacology'>Pharmacology</option>
                                                        <option value='Pharmacy Practice'>Pharmacy Practice</option>
                                                    </select>
                                                </div> : null}
                                            </div>
                                        </div> : null}

                                </div>

                                <div className="pt-10">
                                    <h1 className="text-h1 title pt-14  sm:pt-4 sm:px-4 md:px-4 mdsm:px-4 lg:px-0 sm:text-h3 text-secondary text-left">Payment Details</h1>
                                    <p className="text-primary sm:px-4 md:px-4 font-semibold">Notes</p>
                                </div>
                                <div className="w-[100%] flex flex-wrap sm:px-4 md:px-4 mdsm:px-4 lg:px-0">

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <input
                                            type="text"
                                            name="accountname"
                                            id="accountname"
                                            value={datavalues.accountname}
                                            onChange={(e) => handleChange(e)}
                                            placeholder="Name of the Account holder"
                                            className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                        />
                                        {error.accountname ?
                                            <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.accountname}</span></div> : null}
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <input
                                            type="text"
                                            name="bankname"
                                            id="bankname"
                                            value={datavalues.bankname}
                                            onChange={(e) => handleChange(e)}
                                            placeholder="Name of the bank"
                                            className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                        />
                                        {error.bankname ?
                                            <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.bankname}</span></div> : null}
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%]">
                                        <input
                                            type="text"
                                            name="refnumber"
                                            id="refnumber"
                                            value={datavalues.refnumber}
                                            onChange={(e) => handleChange(e)}
                                            placeholder="UTR / Reference number"
                                            className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                        />
                                        {error.refnumber ?
                                            <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.refnumber}</span></div> : null}
                                    </div>


                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-4">
                                        <h5 className="text-left font-semibold text-sm text-primary">Date of Payment</h5>

                                        <input
                                            type="date"
                                            name="date"
                                            value={datavalues.date}
                                            onChange={(e) => handleChange(e)}
                                            placeholder="Date of payment"
                                            className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]    text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                        />
                                        {error.date ?
                                            <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.date}</span></div> : null}
                                    </div>


                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-8 sm:pt-2">
                                        <input
                                            type="text"
                                            name="amount"
                                            id="amount"
                                            value={datavalues.amount}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e)}
                                            onKeyPress={(event) => { if (!/^[0-9.]+$/.test(event.key)) { event.preventDefault() } }}

                                            placeholder="Amount paid"
                                            className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                        />
                                        {error.amount ?
                                            <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.amount}</span></div> : null}
                                    </div>

                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] sm:pt-2 pt-8 realtive">
                                        <div className="flex">
                                            <label for="file-upload"
                                                className="border mt-[20px] sm:mt-2 bg-icon-color cursor-pointer h-[40px] sm:w-[100%] w-[80%] hover:bg-primary group  duration-300 hover:text-white text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color   border-icon-color  ">
                                                <div className="center h-[100%]">
                                                    {datavalues.bankrecepit !== "" ? <BsFillCloudCheckFill className="m-1 text-base text-primary" /> : <FaCloudUploadAlt className="m-1 text-base text-primary group-hover:text-white" />}
                                           
                                                    <span className="group-hover:text-white text-border-color sm:text-sm mx-2 text-[16px]">Upload Bank Recepit</span>
                                                </div>
                                            </label>
                                            <div></div>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                name="bankrecepit"
                                                className=""
                                                onChange={(e) => handleChange(e)}
                                            />

                                        </div>

                                        {datavalues.bankrecepit != '' ?
                                            <div className=" sm:text-center lg:text-left text-sm text-text-color  ">
                                                {datavalues.bankrecepit.name} </div> : <></>}

                                        {error.bankrecepit ?
                                            <div className="flex pt-3"> <span className="text-add-color text-left font-semibold text-[12px]">{error.bankrecepit}</span></div> : null}
                                    </div>




                                    <div className="lg:w-[33%] sm:w-[100%] md:w-[50%] mdsm:w-[50%] pt-4">
                                        <textarea
                                            name="anydetails"
                                            id="anydetails"
                                            value={datavalues.anydetails}
                                            onChange={(e) => handleChange(e)}
                                            placeholder="Any Details"
                                            className="border-b-[1px] flex focus:placeholder:text-text-black  placeholder:text-[#888888] bg-[#fff] w-[80%]   my-2 text-[16px] focus:outline-none focus:text-black focus:border-black text-text-color sm:w-[100%]  border-border-color py-3 sm:text-sm"
                                        />
                                        {error.anydetails ?
                                            <div className="flex pt-1"> <span className="text-add-color text-left font-semibold text-[12px]">{error.anydetails}</span></div> : null}
                                    </div>


                                </div>




                                <button className="bg-primary sm:my-4 title mt-6 sm:mx-5 sm:text-sm rounded-md text-white py-2 px-16 text-[20px] hover:bg-icon-color hover:text-primary" onClick={() => handleSubmit()}>Submit</button>
                            </div>
                            <div className="w-[33%]  sm:hidden mdsm:hidden md:hidden lg:block ">
                                <img src={capture} className=" object-cover pt-10" />
                            </div>
                        </div>



                    </div>
                </div> */}

                <div className="py-8 bg-text-black">
                        <div className="container mx-auto">
                            <div className="flex flex-wrap py-4">
                                <div className="lg:w-[33%] sm:w-[100%] md:w-[100%] mdsm:w-[100%] pt-2 sm:px-4">
                                    <h1 className="text-h2 text-[#fff] pb-2 text-left tracking-wider sm:text-[18px] title">Contact Us</h1>
                                    <p className="text-left text-[#fff] sm:text-sm tracking-wider leading-[26px] ">
                                        242-B, Trichy Road, Sulur,<br />
                                        Coimbatore, TamilNadu, India<br />
                                    </p>
                                    <div className="flex pt-6">
                                        <div>
                                            <HiOutlineMail className="text-white mx-auto  mr-4 text-[28px]" />
                                        </div>
                                        <a href="mailto:scientia@rvsgroup.com">


                                            <div className="text-left text-[#fff] sm:text-sm tracking-wider leading-[36px]">
                                                scientia@rvsgroup.com <br />
                                            </div>  </a>
                                    </div>

                                    <div className="flex pt-0">
                                        <div>
                                            <HiOutlinePhone className="text-white mx-auto  mr-4 text-[28px]" />
                                        </div>
                                        <div className="text-left text-[#fff] sm:text-sm tracking-wider leading-[36px]">
                                            +91-98420 75383, +91-98948 97258  <br />
                                        </div>
                                    </div>

                                    <div className="w-[100%]">
                                        <div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                
                {/* <div className="bg-white sticky bottom-0 py-2">
                    <div className="">

                        <div className="container mx-auto ">
                            <div className="w-[100%]  flex flex-wrap sm:text-sm md:justify-center md:items-center mdsm:justify-center mdsm:items-center lg:justify-normal ">
                                <div className="lg:w-[80%] mdsm:hidden md:hidden lg:flex sm:hidden ">
                                    <div className="w-[33%]   flex rounded-md py-4 ">
                                        <div className="h-[50px] w-[50px] center rounded-md py-2 px-1 bg-icon-color">
                                            <MdOutlineAppRegistration className="text-primary mx-auto text-[30px]" />
                                        </div>
                                        <div>
                                            <div className="text-left px-4 ">
                                                <p className="font-semibold text-sm">
                                                    Last Date of Registration
                                                </p>
                                                <p className="font-semibold title">
                                                    July 15
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-[33%]  flex rounded-md py-4 ">
                                        <div className="h-[50px] w-[50px] center rounded-md py-2 px-1 bg-icon-color">
                                            <HiOutlineCalendar className="text-primary mx-auto text-[28px]" />
                                        </div>
                                        <div>
                                            <div className="text-left px-4 ">
                                                <p className="font-semibold text-sm">
                                                    Conference
                                                </p>
                                                <p className="font-semibold title">
                                                    27 & 28 July 2023
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-[33%]  flex rounded-md py-4 ">
                                        <div className="h-[50px] w-[50px] center rounded-md py-2 px-1 bg-icon-color">
                                            <HiOutlineLocationMarker className="text-primary mx-auto text-[28px]" />
                                        </div>
                                        <div>
                                            <div className="text-left px-4 ">
                                                <p className="font-semibold text-sm">
                                                    Venue
                                                </p>
                                                <p className="font-semibold title">
                                                    Kalam Hall
                                                </p>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <div className="lg:w-[20%] mdsm:w-[30%] md:w-[30%] sm:w-[100%] center">

                                    <button onClick={() => downloadRegister([{
                                        name: "Account Details",
                                        link: require("../Asset/Account-details.pdf")
                                    },
                                    ])} className="bg-primary sm:w-auto  mdsm:px-8 mdsm:py-2 md:px-8 md:py-2 title rounded-md w-[100%] text-white py-2 px-16 text-[20px] hover:bg-icon-color hover:text-primary">
                                        Register Here
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}



            </div>

        </>


    )
}

export default Banner