import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Drugdiscovery from './Pages/Drugdiscovery';
import "./Components/CSS/Components.css"
import "./Components/CSS/Responsive.css"
import Page404 from './Pages/Page404';
import ThankYou from './Pages/ThankYou';


function App() {
  return (
    <div>
      <BrowserRouter>
        <HelmetProvider >
          <Routes>
            <Route path="/" element={<Drugdiscovery />} />
            <Route path="*" element={<Page404 />} />
            {!localStorage.pharmacy ? <></> : <Route path="thankyou" element={<ThankYou />} />}
          </Routes>
        </HelmetProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
