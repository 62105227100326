import React from "react";

function Footer() {
    return (
        <div className="py-8 bg-text-black">
            <div className="container mx-auto">
                <div className="flex flex-wrap py-4">
                    <div className="w-[33%]">
                        <h1 className="text-h2 text-[#fff] pb-2 text-left tracking-wider title">Contact Us</h1>
                        <p className="text-left text-[#fff] tracking-wider leading-[26px]"> 
                            242-B, Trichy Road, Sulur,<br />
                            Coimbatore, TamilNadu, India<br />
                            Office: 0422 2687421 / 603<br />
                            Extn: 225</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Footer