import React from 'react'
import Doctor from '../Components/Asset/doctor-checking.webp'
import PageNotFound from '../Components/Asset/404.png'

export default function Page404() {
  return (
    <div className='flex justify-center'>
        <img src={PageNotFound} className='w-[400px]'/>
    </div>
  )
}
