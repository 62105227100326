import React from "react"
import Banner from "../Components/Webinar/Banner"
import Webniar from "../Components/Webinar/Webniar"

function Drugdiscovery(){
    return(
        <div>
            <Banner/>
            {/* <Webniar/> */}
        </div>
    )
}

export default Drugdiscovery