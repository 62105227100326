import React, { useEffect } from 'react'
import { BiArrowBack } from "react-icons/bi";
import { FcCallback } from "react-icons/fc";
import { Link } from 'react-router-dom';
import RVSLogo from "../Components/Asset/Group.png";
import RVSLogo1 from "../Components/Asset/Grop White.png";
import { BsCheckCircleFill } from "react-icons/bs";

export default function ThankYou() {
    useEffect(() => {
        setTimeout(() => {
            localStorage.clear()
            window.location = "/"
        }, 4000)
    }, [])
    return (
        <div className="h-[100vh] sm:h-[90vh] block overflow-hidden">
            <div className="flex w-[100%]  sm:block h-[100%]">
                <div className="w-[60%] sm:hidden bg-primary">
                    <div className='flex justify-center items-center h-[100%]'>
                        <img src={RVSLogo1} className="h-[300px] center" />
                    </div>
                </div>
                <div className="lg:w-[40%] mdsm:w-[100%] overflow-hidden sm:w-[100%] h-[100%] center">
                    <div className="block py-16">
                        <div className="center lg:p-10">
                            <img src={RVSLogo} className="h-[200px] thankyou-logo" />
                        </div>
                        <div className="center">
                            <div className="">
                                <BsCheckCircleFill className="text-[#2e8500] text-[48px] text-center" />
                            </div>
                        </div>

                        <div className="bg-[#fff] p-4 pb-10 pt-2">
                            <div className="text-[36px] text-center sm:text-[26px] text-[#252525] sm:pb-3 md:pb-3 mdsm:pb-3 tracking-wide thankyou-font">Thank You for Registering</div>
                            <div>

                            </div>

                            <div className="center">
                                <div className="flex pt-8">
                                    <FcCallback className="text-[40px]" />
                                    <p className="px-2 text-[#252525] font-semibold  tracking-wider sm:text-[24px] text-[26px]">+91 98420 75383</p>
                                </div>
                            </div>

                            <div className="center pt-4 sm:pt-4">
                                <Link to="/">
                                    <button className="bg-primary hover:bg-[#3e3e3e] tracking-wider flex transition07 text-[#fff] font-semibold px-4 py-2 borderRadius"> <span className="pr-4"><BiArrowBack className="text-[26px]" /></span>  Home</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
